import React from 'react';

import { Container } from '@components/Layout/Container/Container';
import { LogosRow } from '@components/LogosRow/LogosRow';
import { Padding } from '@components/Padding/Padding';
import { mainLogos } from '@components/Pages/Home/HomeHeader/HomeHeader';

import styles from './AboutUsLogoRow.module.scss';

export const AboutUsLogoRow: React.FC = () => (
  <Padding className={styles.wrapper} size={{ x: 0, y: 40 }}>
    <Container>
      <LogosRow logos={mainLogos} note="Trusted by" />
    </Container>
  </Padding>
);
